.AddnewDocbutton {
    --main-focus: #2d8cf0;
    --font-color: #dedede;
    --bg-color-sub: #222;
    --bg-color: #323232;
    --main-color: #dedede;
    position: relative;
    width: 130px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    background-color: var(--bg-color);
    border-radius: 10px;
    overflow: hidden;
  }


  
  .AddnewDocbutton, .button__icon, .button__text {
    transition: all 0.3s;
  }
  
  .AddnewDocbutton .button__text {
    transform: translateX(15px);
    color: var(--font-color);
    font-weight: 600;
  }


  
  .AddnewDocbutton .button__icon {
    position: absolute;
    transform: translateX(95px);
    height: 100%;
    width: 39px;
    background-color: var(--bg-color-sub);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AddnewLeavebutton .button__icon {
    transform: translateX(106px);
    width: 35px;
}
  
  .AddnewDocbutton .svg {
	width: 20px;
	stroke: var(--main-color);
	margin-right: 7px;
}
  
  .AddnewDocbutton:hover {
    background: var(--bg-color);
  }
  
  .AddnewDocbutton:hover .button__text {
    color: transparent;
  }
  
  .AddnewDocbutton:hover .button__icon {
    width: 148px;
    transform: translateX(0);
  }
  
  .AddnewDocbutton:active {
    transform: translate(3px, 3px);
    box-shadow: 0px 0px var(--main-color);
  }

  .spinner {
    --red: #e2e2e2;
    --blue: #e2e2e2;
    --green: #e2e2e2;
    --yellow: #e2e2e2;
    position: relative;
    width: 60px;
  }
  
  .spinner:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  
  .circular {
    animation: rotate73451 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash0175 1.5s ease-in-out infinite, color7123 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  
  @keyframes rotate73451 {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash0175 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
  
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  
  @keyframes color7123 {
    100%, 0% {
      stroke: var(--red);
    }
  
    40% {
      stroke: var(--blue);
    }
  
    66% {
      stroke: var(--green);
    }
  
    80%, 90% {
      stroke: var(--yellow);
    }
  }
  

   .loader_main_component_wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #241f1f66;
    position: fixed;
    z-index: 1;
    top : 0;
}

.custom-loader {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 40%,#000 41%);
  background: linear-gradient(0deg ,#766DF480 50%,#766DF4FF 0) center/4px 100%,
    linear-gradient(90deg,#766DF440 50%,#766DF4BF 0) center/100% 4px;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

.tab_loadermain-wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
	height: 50px;
}

.Nodatafound-img-box img {
  width: 20%;
  margin: 0 auto;
  display: flex;
  margin-top: 42px;
  mix-blend-mode: darken;
}

/* .NoDataFound_main_wrapper h4 {
	margin-top: -14px;
} */

.Card_loader_main_wrapper.mt-5 {
	width: 97vw;
}

/* Download Cashless Card button */
.Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  padding: 15px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
  width: 33%;
  border-radius: 40px!important;
  transition-duration: .3s;
}

/* plus sign */
.sign {
	width: 4%;
	transition-duration: .3s;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}
/* text */
.text {
	position: absolute;
	right: 6%;
	width: 80%;
	padding-right: 10px;
	color: white;
	font-size: 14px;
	font-weight: 600;
	bottom: 13px;
}
/* hover effect on button width */
.Btn:hover {
  width: 28%;
  border-radius: 40px;
  transition-duration: .3s;
  transform: scale(1.05);
}

/* button click effect*/
.Btn:active {
  transform: translate(2px ,2px);
}

.AddnewLeavebutton {
  --main-focus: #2d8cf0;
  --font-color: #dedede;
  --bg-color-sub: #222;
  --bg-color: #323232;
  --main-color: #dedede;
  position: relative;
  width: 140px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 20px;
  overflow: hidden;
}

.react-datepicker {
  background-color: white!important;
  border-radius: 15px !important;
  overflow: hidden !important;
  box-shadow: rgb(0 0 0 / 11%) 0px 3px 8px !important;
}

.react-datepicker__header {
  background-color: #5d5b68ba !important;
  color: white !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: white !important;
}
.react-datepicker__day-name {
  color: white !important;
}
.react-datepicker__day.react-datepicker__day--018.react-datepicker__day--selected {
  background-color: #898791 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}


.submit-btn {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255,0,0);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255,0,0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.submit-btn span {
  margin: 10px;
}

.submit-btn::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.submit-btn:hover {
  color: #fff;
  border: 1px solid rgb(255,0,0);
}

.submit-btn:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 0, 0);
}

button.AddnewDocbutton.AddnewLeavebutton .button__text {
  font-size: 14px;
}